import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { isExternalRecruiterSelector } from 'store/viewer'

import CandidateStatusMenu from 'app/components/Candidate/UpdateCandidateStatus/CandidateStatusMenu'

import Badge from 'antd/lib/badge'
import Dropdown from 'antd/lib/dropdown'
import Tooltip from 'antd/lib/tooltip'

import {
  CheckOutlined,
  CloseOutlined,
  QuestionOutlined,
} from '@ant-design/icons'

import {
  CandidateStatusMenuContainer,
  Container,
  TagItem,
  TagItemMaybe,
  TagItemNew,
  TagItemRejected,
  TagItemShortlisted,
} from './styles'

import { CandidateStatus, IAlgoliaCandidate, JobStatus, RootState } from 'types'

const getCandidateStatus = (status: CandidateStatus) => {
  switch (status) {
    case CandidateStatus.New:
      return <TagItemNew color="processing">New</TagItemNew>
    case CandidateStatus.Shortlisted:
      return <TagItemShortlisted icon={<CheckOutlined />} />
    case CandidateStatus.Viewed:
      return <TagItem icon={<Badge color="#F0F0F0" />} color="default" />
    case CandidateStatus.Maybe:
      return <TagItemMaybe color="warning" icon={<QuestionOutlined />} />
    case CandidateStatus.Rejected:
      return <TagItemRejected icon={<CloseOutlined />} />
    case CandidateStatus.AutoRejected:
      return <TagItem color="red">AUTO REJECTED</TagItem>
    default:
      return null
  }
}

const Status: FC<IAlgoliaCandidate> = ({ status, objectID, advertId }) => {
  const [isVisibleDropdown, setIsVisibleDropdown] = useState(false)

  const handleOnUpdateCandidates = () => {
    setIsVisibleDropdown(false)
  }

  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)
  const { status: jobStatus } = useSelector(
    (state: RootState) => state.jobs.fetchJob.job!,
  )

  const isStatusVisible =
    !isExternalRecruiter && jobStatus !== JobStatus.Archived

  return (
    <Container>
      <Tooltip
        title={
          status === CandidateStatus.AutoRejected ? 'Auto rejected' : status
        }
        placement="topLeft"
        overlayClassName="candidate-tooltip"
      >
        {isStatusVisible ? (
          <Dropdown
            arrow
            trigger={['click']}
            visible={isVisibleDropdown}
            overlay={
              <CandidateStatusMenuContainer onClick={e => e.stopPropagation()}>
                <CandidateStatusMenu
                  status={status}
                  candidatesId={[objectID]}
                  onUpdateStatuses={handleOnUpdateCandidates}
                  jobId={String(advertId)}
                />
              </CandidateStatusMenuContainer>
            }
            onVisibleChange={e => {
              setIsVisibleDropdown(e)
            }}
          >
            <div
              onClick={e => {
                e.stopPropagation()
                setIsVisibleDropdown(true)
              }}
            >
              {getCandidateStatus(status)}
            </div>
          </Dropdown>
        ) : (
          <div>{getCandidateStatus(status)}</div>
        )}
      </Tooltip>
    </Container>
  )
}

export default Status
