import api from '../utils/ApiInstance'
import qs from 'qs'

import {
  ApiAddCandidates,
  ApiCreateStage,
  ApiDeleteCandidate,
  ApiDeleteStage,
  ApiDeleteUser,
  ApiDownloadCV,
  ApiListCandidates,
  ApiPublishJob,
  ApiUpdateCandidatesStage,
  ApiUpdateCandidatesStatus,
  ApiUpdateUser,
  ArchiveJob,
  ExpireJob,
  IAddViewer,
  IApiFetchCandidateNotes,
  IApiFetchCandidateTimeline,
  IApiForwardCandidates,
  IApiPostCandidateNote,
  IApproveJob,
  IApproveUser,
  ICreateJob,
  ICreateUser,
  IInviteMember,
  IJobForwardSettings,
  IPurchaseCredits,
  IResetPassword,
  ITransferCredits,
  IUpdateHT,
  IUpdateUserCompany,
  IViewerCompany,
  MoveUserToAnotherCompany,
  UsersRole,
} from 'types'

type GetJobs = {
  pageNumber: number
  pageSize: number
}

type GetUsers = {
  userStatuses: string[]
  pageNumber?: number
  pageSize?: number
  companyId?: string
}

type ApproveCompany = {
  id: number
}

const Api = {
  _setAccessTokenToAxios({ accessToken }: { accessToken: string | null }) {
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  },
  _removeAccessTokenFromAxios() {
    delete api.defaults.headers.common['Authorization']
  },
  auth: {
    getViewer: () => api.get('/user/signed-in').then(({ data }) => data),

    signUp: ({ viewer }: { viewer: IAddViewer }) =>
      api.post('/user/sign-up', viewer).then(({ data }) => data),

    resendSignUp: ({ email }: { email: string }) =>
      api.post('/user/email-verification', { email }).then(({ data }) => data),

    singUpCompany: ({ viewerCompany }: { viewerCompany: IViewerCompany }) =>
      api.post('/user/social-sign-up', viewerCompany).then(({ data }) => data),

    forgotPassword: ({ email }: { email: string }) =>
      api.post('/user/reset-password', { email }).then(({ data }) => data),
    resetPassword: (data: IResetPassword) =>
      api.post('/user/set-password', data).then(({ data }) => data),
  },
  users: {
    getUser: ({ userId }: { userId: string }) =>
      api.get(`/user/${userId}`).then(({ data }) => data),

    updateUser: (user: ApiUpdateUser) =>
      api.put(`/user`, { ...user }).then(({ data }) => data),

    getUsers: ({ userStatuses, pageNumber, pageSize, companyId }: GetUsers) =>
      api
        .get('/user', {
          params: {
            userStatuses,
            pageNumber,
            pageSize,
            companyId,
          },
          paramsSerializer: function (p) {
            return qs.stringify(p, { arrayFormat: 'repeat' })
          },
        })
        .then(({ data }) => data),

    approveUser: ({ userId, role, companyId }: IApproveUser) =>
      api
        .post('/user/approve', { userId, role, companyId })
        .then(({ data }) => data),

    createUser: (data: ICreateUser) =>
      api.post('/user', { ...data }).then(({ data }) => data),

    inviteMember: ({
      userId,
      adverts,
      email,
      role,
      companyId,
    }: IInviteMember) =>
      api
        .post('/user/invite', { userId, adverts, email, role, companyId })
        .then(({ data }) => data),

    changeUserRole: ({ userId, role }: { userId: string; role: UsersRole }) =>
      api
        .post(`/user/updaterole`, { id: userId, role })
        .then(({ data }) => data),

    updateUserCompany: (data: IUpdateUserCompany) =>
      api.put('/companies', { ...data }).then(({ data }) => data),

    moveUserToAnotherCompany: (data: MoveUserToAnotherCompany) =>
      api.post('/user/move', { ...data }).then(({ data }) => data),

    deleteUser: (data: ApiDeleteUser) =>
      api.delete('/user', { data: { ...data } }).then(({ data }) => data),
  },
  credits: {
    purchaseCredits: (payload: IPurchaseCredits) =>
      api.post('/credits/purchase', { ...payload }).then(({ data }) => data),

    getCheckoutInfo: () => api.get('/credits').then(({ data }) => data),

    transferCredits: (payload: ITransferCredits) =>
      api.post('/user/transfer-credits', payload).then(({ data }) => data),
  },

  jobs: {
    getJobPreview: ({ jobId }: { jobId: string }) =>
      api.get(`/campaign/${jobId}/preview`).then(({ data }) => data),

    getJob: ({ jobId }: { jobId: string }) =>
      api.get(`/campaign/${jobId}`).then(({ data }) => data),

    getJobs: ({ pageNumber, pageSize }: GetJobs) =>
      api
        .get('/campaign', { params: { pageNumber, pageSize } })
        .then(({ data }) => data),

    createJob: ({ job }: { job: ICreateJob }) =>
      api.post('/campaign', { ...job }).then(({ data }) => data),

    updateJob: ({ job }: { job: ICreateJob }) =>
      api.put('/campaign', { ...job }).then(({ data }) => data),

    deleteJob: ({ jobId }: { jobId: string }) =>
      api.delete(`/campaign/${jobId}`).then(({ data }) => data),

    cloneJob: ({ id, campaignType }: { id: number; campaignType: string }) =>
      api.post('/campaign/copy', { id, campaignType }).then(({ data }) => data),

    approve: ({ advertId, creditType }: IApproveJob) =>
      api
        .post('/campaign/approve', { id: advertId, creditType })
        .then(({ data }) => data),

    publishJob: ({ id, creditType, userApproval }: ApiPublishJob) =>
      api
        .post('/campaign/publish', { id, creditType, userApproval })
        .then(({ data }) => data),

    expireJob: ({ id, undo }: ExpireJob) =>
      api.post('/campaign/expire', { id, undo }).then(({ data }) => data),

    archiveJob: ({ id, undo }: ArchiveJob) =>
      api.post('/campaign/archive', { id, undo }).then(({ data }) => data),

    unArchiveJob: ({ id }: { id: string }) =>
      api.post('/campaign/unarchive', { id }).then(({ data }) => data),

    promoteJob: ({ id }: { id: number }) =>
      api.post('/campaign/promote', { id }).then(({ data }) => data),

    updateJobForwardSettings: (payload: IJobForwardSettings) =>
      api.post('/campaign/forwarding', payload),
  },

  hiringTeam: {
    getMembers: ({ advertId }: { advertId: string }) =>
      api.get('/hiringteam', { params: { advertId } }).then(({ data }) => data),

    inviteMember: ({ id, userId }: IUpdateHT) =>
      api.post('/hiringteam', { id, userId }).then(({ data }) => data),

    deleteMember: ({ id, userId }: IUpdateHT) =>
      api
        .delete('/hiringteam', { data: { id, userId } })
        .then(({ data }) => data),
  },

  companies: {
    approveCompany: ({ id }: ApproveCompany) =>
      api.post('/companies/approve', { id }).then(({ data }) => data),
  },

  candidates: {
    getCandidate: ({ candidateId }: { candidateId: string }) =>
      api.get(`/candidate/${candidateId}`).then(({ data }) => data),

    listCandidates: (params: ApiListCandidates) =>
      api.get('/candidate/list', { params }).then(({ data }) => data),

    fetchCandidateCount: (params: ApiListCandidates) =>
      api.get('/candidate/count', { params }).then(({ data }) => data),

    addCandidate: (candidateFormData: FormData) =>
      api
        .post('/candidate/manually', candidateFormData)
        .then(({ data }) => data),

    updateCandidate: (candidate: FormData) =>
      api.put('/candidate', candidate).then(({ data }) => data),

    addCandidates: (candidates: ApiAddCandidates) =>
      api.post('/candidate', candidates).then(({ data }) => data),

    parseCandidateByER: (cvFormData: any) =>
      api.post('/candidate/parse', cvFormData).then(({ data }) => data),

    updateCandidatesStatus: ({
      candidatesId,
      status,
    }: ApiUpdateCandidatesStatus) =>
      api
        .post('/candidate/update-status', { ids: candidatesId, status })
        .then(({ data }) => data),

    updateCandidatesStage: ({ ids, stageId }: ApiUpdateCandidatesStage) =>
      api
        .post('/candidate/update-stage', { ids, stageId })
        .then(({ data }) => data),

    postCandidateNote: ({ id, text, users }: IApiPostCandidateNote) =>
      api
        .post(`/candidate/${id}/note`, { text, users })
        .then(({ data }) => data),

    fetchCandidateTimeline: ({
      candidateId,
      pageNumber,
    }: IApiFetchCandidateTimeline) =>
      api
        .get(`/candidate/${candidateId}/timeline`, {
          params: {
            PageNumber: pageNumber,
            PageSize: 50,
          },
        })
        .then(({ data }) => data),

    fetchCandidateNotes: ({
      candidateId,
      pageNumber,
    }: IApiFetchCandidateNotes) =>
      api
        .get(`/candidate/${candidateId}/note`, {
          params: {
            PageNumber: pageNumber,
            PageSize: 50,
          },
        })
        .then(({ data }) => data),

    deleteCandidate: ({ id }: ApiDeleteCandidate) =>
      api.delete('/candidate', { data: { id } }).then(({ data }) => data),

    downloadCv: ({ id }: ApiDownloadCV) =>
      api
        .get(`candidate/${id}/download`, {
          responseType: 'blob',
        })
        .then(response => response),

    forwardCandidates: ({ ids, emailList, comment }: IApiForwardCandidates) =>
      api
        .post(`/candidate/forward`, {
          ids,
          emailList,
          comment,
        })
        .then(response => response),
  },

  stages: {
    getStages: ({ companyId }: { companyId: string }) =>
      api.get(`/stage`, { params: { companyId } }).then(({ data }) => data),

    createStage: (stage: ApiCreateStage) =>
      api.post('/stage', stage).then(({ data }) => data),

    updateStage: (stage: ApiCreateStage) =>
      api.put('/stage', stage).then(({ data }) => data),

    deleteStage: (stage: ApiDeleteStage) =>
      api.delete('/stage', { data: stage }).then(({ data }) => data),
  },

  algoliaSync: {
    candidates: {
      updateCandidatesStatus: ({
        candidatesId,
        status,
      }: ApiUpdateCandidatesStatus) =>
        api
          .post('/candidate/update-status-algolia', {
            ids: candidatesId,
            status,
          })
          .then(({ data }) => data),

      updateCandidatesStage: ({ ids, stageId }: ApiUpdateCandidatesStage) =>
        api
          .post('/candidate/update-stage-algolia', { ids, stageId })
          .then(({ data }) => data),
    },
  },
}

export default Api
