import React, { useEffect, useMemo, useState } from 'react'
import { InstantSearch } from 'react-instantsearch-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { getRefreshSelector } from 'store/app/selectors'
import {
  fetchCandidateCountByFiltersPromiseCreator,
  fetchCandidateCountByKeywordPromiseCreator,
  listCandidatesPromiseCreator,
} from 'store/candidates'
import { clearJob, fetchJobPromiseCreator } from 'store/jobs'
import { fetchStagesPromiseCreator } from 'store/stages'
import { getViewerSelector } from 'store/viewer'

import { checkIfCanInviteToLiveArchiveJob, createURL, useQuery } from 'helpers'
import { parseQsDuplicateKeys } from 'helpers/apiHelpers'
import { getJobCandidatesStat } from 'helpers/candidateHelpers'

import CandidatesList from './CandidatesList/CandidatesList'
import { FilterAttribute } from './CandidatesList/RegularFilter/FilterList'
import algoliasearch from 'algoliasearch/lite'
import DetailJob from 'app/components/Jobs/DetailJob/DetailJob'
import HiringTeam from 'app/components/Jobs/JobHiringTeam/JobHiringTeam'
import JobSettings from 'app/components/Jobs/JobSettings/JobSettings'
import InviteTeamMember from 'app/components/Modals/InviteTeamMember/InviteTeamMember'
import snackbar from 'app/components/Snackbar/Snackbar'
import BackButton from 'app/components/UI/Buttons/BackButton/BackButton'
import Plus from 'assets/icons/Plus'
import { API_PAGE_START, APP_PAGE_START, defaultPageSize } from 'constants/app'
import {
  RESET_PAGE_NUMBER,
  SKIP_COUNT_REFRESH,
  TRUE,
} from 'constants/candidates'
import useActionsRoutines from 'hooks/useActionsRoutines'
import useScrollDirection from 'hooks/useScrollDirection'
import useSearchToQuery from 'hooks/useSearchToQuery'

import Spin from 'antd/lib/spin'
import Tabs from 'antd/lib/tabs'

import { RejectedCandidates } from './RejectedCounter/styles'
import {
  ColItem,
  Container,
  HeaderContainer,
  HeaderRow,
  SpinContainer,
} from './styles'

import {
  ApiListCandidates,
  CandidateSortCriteria,
  CandidateStatus,
  JobDetailTab,
  JobPage,
  JobStatus,
  RootState,
  SortOrder,
} from 'types'

export interface FilterProp {
  status: string[]
  stage: string[]
  source: string[]
}

export interface OnFilterProp {
  key: FilterAttribute
  value: string | string[]
}

const { TabPane } = Tabs

const JobDetail = () => {
  const history = useHistory()
  const query = useQuery()
  const { jobId } = useParams<{ jobId: string }>()
  const defaultTabQuery = query.get('tab') || JobDetailTab.JobDetails
  const scrollDirection = useScrollDirection()
  const dispatch = useDispatch()
  const viewer = useSelector(getViewerSelector)
  const fetchJob = useActionsRoutines(fetchJobPromiseCreator)
  const refreshAlgoliaCache = useSelector(getRefreshSelector)
  const { job, isLoading } = useSelector(
    (state: RootState) => state.jobs.fetchJob,
  )

  // Get QS Filters
  const qsLimit = query.get('limit') || defaultPageSize
  const qsPageNumber = query.get('page') || undefined
  const qsSearch = query.get('query') || ''
  const qsSource = query.get('source') || undefined
  const qsStage = query.get('stage') || undefined
  const qsStatus = query.get('status') || undefined
  const [showRejectedOnly, setShowRejectedOnly] = useState<boolean>(false)
  const [filters, setFilters] = useState<FilterProp>({
    source: qsSource ? qsSource.split(',') : [],
    stage: qsStage ? qsStage.split(',') : [],
    status: qsStatus ? qsStatus.split(',') : [],
  })

  const stopSearch = defaultTabQuery !== JobDetailTab.Candidates
  const { onSearchStateChange, searchState } = useSearchToQuery({
    stopSearch,
    setItem: 'candidateSearch',
  })

  // Get Job Details and Company Stages

  const fetchStages = useActionsRoutines(fetchStagesPromiseCreator)

  useEffect(() => {
    fetchJob({ jobId }).then(({ job: { companyId } }) =>
      fetchStages({ companyId }),
    )
    return () => {
      dispatch(clearJob())
    }
  }, [])

  // List Candidates

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID!,
        viewer.algoliaKeys.candidates.token,
      ),
    [],
  )

  const listCandidatesAction = useActionsRoutines(listCandidatesPromiseCreator)
  const fetchCandidateCountByKeyword = useActionsRoutines(
    fetchCandidateCountByKeywordPromiseCreator,
  )
  const fetchCandidateCountByFilters = useActionsRoutines(
    fetchCandidateCountByFiltersPromiseCreator,
  )
  const { data: candidates, isLoading: isListLoading } = useSelector(
    (state: RootState) => state.candidates.listCandidates,
  )
  const { data: candidateCountByKeyword } = useSelector(
    (state: RootState) => state.candidates.fetchCandidateCountByKeyword,
  )

  /**
   * Removal of Algolia in Candidates List request
   * https://jobheron.atlassian.net/browse/JA-1490
   */
  const listCandidates = ({
    candidateStageTypes,
    candidateStatuses,
    candidateSources,
    pageSize,
    pageNumber = 0,
    searchString,
  }: ApiListCandidates) => {
    const isResetPage = sessionStorage.getItem(RESET_PAGE_NUMBER)
    sessionStorage.removeItem(RESET_PAGE_NUMBER)

    // Update qs
    const qs = parseQsDuplicateKeys({
      query: searchString,
      status: candidateStatuses ? candidateStatuses.join(',') : undefined,
      stage: candidateStageTypes ? candidateStageTypes.join(',') : undefined,
      source: candidateSources ? candidateSources.join(',') : undefined,
      page: isResetPage ? APP_PAGE_START : pageNumber + 1,
      limit: pageSize,
    }).toString()
    sessionStorage.setItem('candidateSearch', qs)
    history.replace({ search: `?tab=${JobDetailTab.Candidates}&${qs}` })

    // API Request: Get List of Candidates based on filters
    const params = parseQsDuplicateKeys({
      advertId: Number(jobId),
      candidateStageTypes,
      candidateStatuses,
      candidateSources,
      pageSize,
      pageNumber: isResetPage ? API_PAGE_START : pageNumber,
      searchString,
      sortOrder: SortOrder.DESC,
      sortingCriteria: CandidateSortCriteria.APPLIED,
    })

    listCandidatesAction(params).catch(() => {
      snackbar({
        content: candidates.numberOfElements
          ? 'Unable to apply new filter'
          : 'Unable to retrieve list of candidates.',
        showIcon: true,
        type: 'error',
        closable: true,
      })
    })

    if (sessionStorage.getItem(SKIP_COUNT_REFRESH)) {
      sessionStorage.removeItem(SKIP_COUNT_REFRESH)
      return
    }

    // API Request: Get Count based on filters
    const statParams = parseQsDuplicateKeys({
      advertId: Number(jobId),
      searchString,
    })

    fetchCandidateCountByKeyword(statParams)
    fetchCandidateCountByFilters(params)
  }

  const onSearchTyping = (value: string) => {
    // Reset page number, retain limit
    const qs = parseQsDuplicateKeys({
      query: value,
      page: 1,
      limit: Number(qsLimit),
    }).toString()
    history.replace({ search: `?tab=${JobDetailTab.Candidates}&${qs}` })
  }

  const onSearchSubmit = (value: string) => {
    clearFilters()
  }

  const onFilter = ({ key, value }: OnFilterProp) => {
    if (key === FilterAttribute.stage) {
      sessionStorage.setItem(SKIP_COUNT_REFRESH, TRUE)
    }
    sessionStorage.setItem(RESET_PAGE_NUMBER, TRUE)

    setShowRejectedOnly(false)
    setFilters(prev => {
      // Set direct filter
      if (Array.isArray(value)) {
        return {
          ...prev,
          [key]: prev[key].length ? [] : value,
        }
      }

      // Set toggled filter
      const newValue =
        key in prev && prev[key].includes(value)
          ? prev[key].filter(data => data !== value)
          : [...prev[key], value]

      return {
        ...prev,
        [key]: newValue,
      }
    })
  }

  const clearFilters = () => {
    sessionStorage.setItem(RESET_PAGE_NUMBER, TRUE)
    setShowRejectedOnly(false)
    setFilters({
      source: [],
      stage: [],
      status: [],
    })
  }

  const onFilterRejected = () => {
    if (!showRejectedOnly) {
      setFilters({
        source: [],
        stage: [],
        status: [CandidateStatus.Rejected, CandidateStatus.AutoRejected],
      })
      setShowRejectedOnly(true)
      return
    }
    clearFilters()
  }

  const onPageChange = (page: number, _: number) => {
    sessionStorage.setItem(SKIP_COUNT_REFRESH, TRUE)
    listCandidates({
      candidateSources: filters.source,
      candidateStageTypes: filters.stage,
      candidateStatuses: filters.status,
      pageNumber: page - 1,
      pageSize: candidates.size,
      searchString: qsSearch,
    })
  }

  useEffect(() => {
    // On load
    listCandidates({
      candidateSources: filters.source,
      candidateStageTypes: filters.stage,
      candidateStatuses: filters.status,
      pageNumber: qsPageNumber ? Number(qsPageNumber) - 1 : undefined,
      pageSize: Number(qsLimit),
      searchString: qsSearch,
    })
  }, [qsSearch, filters])

  // Render

  const rejectedCount =
    getJobCandidatesStat(
      candidateCountByKeyword.statusCount,
      CandidateStatus.Rejected,
    ) +
    getJobCandidatesStat(
      candidateCountByKeyword.statusCount,
      CandidateStatus.AutoRejected,
    )

  const isCanInviteToJob = checkIfCanInviteToLiveArchiveJob({
    jobStatus: job?.status,
    activeTab: defaultTabQuery,
    userRole: viewer.role,
  })

  const handleChangeTabs = (activeKey: any) => {
    history.replace({ search: `?tab=${activeKey}` })
  }

  if (!job || isLoading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    )
  }

  const { title } = job

  const TabRightContent = {
    right: (
      <>
        {isCanInviteToJob && job.status !== JobStatus.Archived && (
          <InviteTeamMember
            title="Invite New Member"
            type="link"
            icon={<Plus />}
            jobId={job.id}
            companyId={job.companyId}
            jobStatus={job.status}
          />
        )}
        {defaultTabQuery === JobDetailTab.Candidates && (
          <RejectedCandidates
            onClick={onFilterRejected}
            isRejectedActive={showRejectedOnly}
          >
            Rejected Candidates <span>{rejectedCount}</span>
          </RejectedCandidates>
        )}
      </>
    ),
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={viewer.algoliaKeys.candidates.indexName}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
      refresh={refreshAlgoliaCache}
    >
      <Container>
        <HeaderContainer scrollDirection={scrollDirection}>
          <HeaderRow>
            <ColItem xs={24} sm={24} md={10} lg={10}>
              <BackButton title={title} type="jobs" />
            </ColItem>
            <ColItem xs={24} sm={24} md={14} lg={14}>
              <JobSettings
                search={qsSearch}
                onSearchTyping={onSearchTyping}
                onSearchSubmit={onSearchSubmit}
                job={job}
                isCanInviteToJob={isCanInviteToJob}
              />
            </ColItem>
          </HeaderRow>
        </HeaderContainer>

        <Tabs
          onChange={handleChangeTabs}
          defaultActiveKey={defaultTabQuery}
          tabBarExtraContent={TabRightContent}
        >
          <TabPane tab="Candidates" key={JobDetailTab.Candidates} forceRender>
            <CandidatesList
              candidates={candidates}
              filters={filters}
              onClearFilters={clearFilters}
              onFilter={onFilter}
              onPageChange={onPageChange}
              isLoading={isListLoading}
            />
          </TabPane>
          <TabPane tab="Hiring Team" key={JobDetailTab.HiringTeam}>
            <HiringTeam job={job} />
          </TabPane>
          <TabPane tab="Job details" key={JobDetailTab.JobDetails}>
            <DetailJob job={job} page={JobPage.DetailJob} />
          </TabPane>
        </Tabs>
      </Container>
    </InstantSearch>
  )
}

export default JobDetail
