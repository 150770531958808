import React, { FC, MouseEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  archiveJobPromiseCreator,
  unArchiveJobPromiseCreator,
} from 'store/jobs'

import CloneJob from '../../Jobs/CloneJob/CloneJob'
import snackbar from 'app/components/Snackbar/Snackbar'
import Warning from 'assets/icons/Warning'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'

import { ModalFooter, ModalItem, Title } from './styles'

import { ArchiveJobType, RootState } from 'types'

interface ArchiveJobModalProps {
  title: string
  type: ArchiveJobType
  jobId: number
  icon?: React.ReactNode
  buttonTitle: string
  buttonType?: 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default'
  isJHCampaign?: boolean
}

const ArchiveJobModal: FC<ArchiveJobModalProps> = ({
  title,
  icon,
  buttonType = 'text',
  jobId,
  buttonTitle,
  type,
  isJHCampaign,
}) => {
  const history = useHistory()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const archiveJob = useActionsRoutines(archiveJobPromiseCreator)
  const unArchiveJob = useActionsRoutines(unArchiveJobPromiseCreator)
  const isArchiveJobLoading = useSelector(
    (state: RootState) => state.jobs.archiveJob.isLoading,
  )
  const isUnArchiveJobLoading = useSelector(
    (state: RootState) => state.jobs.unArchiveJob.isLoading,
  )
  const handleArchiveJob = ({ undo = false }) => {
    archiveJob({ id: jobId, undo }).then(() => {
      handleCloseModal()
      if (undo) return
      snackbar({
        content: 'Job has been archived.',
        undoFunc: handleUndoArchiveJob,
        showIcon: true,
        type: 'info',
      })
    })
  }

  const handleUnArchiveJob = () => {
    unArchiveJob({ id: jobId }).then(() => {
      snackbar({
        content: 'Job reposted.',
        showIcon: true,
        type: 'info',
      })
      handleCloseModal()
      history.replace(`${ROUTES.JOB}/${jobId}/preview`)
    })
  }

  const handleUndoArchiveJob = () => {
    handleArchiveJob({ undo: true })
  }

  const handleOpenModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  return (
    <>
      <Button type={buttonType} onClick={handleOpenModal} icon={icon}>
        {buttonTitle}
      </Button>
      <ModalItem
        width={550}
        footer={null}
        title={
          <Title>
            {type === ArchiveJobType.Archive ? (
              <>
                <Warning size={18} />
                Archive {title} job?
              </>
            ) : (
              'Repost job?'
            )}
          </Title>
        }
        visible={isOpenModal}
        onCancel={handleCloseModal}
      >
        {type === ArchiveJobType.Archive && (
          <ul>
            <li>
              Archiving a job will expire it on any job boards it is live on.
            </li>
            <li>Archiving this job will change it to 'read only' mode.</li>
            <li>
              It will not be possible to make changes, add comments or manage
              the candidates for this position.
            </li>
            <li>It cannot be made live again without additional cost.</li>
          </ul>
        )}
        {type === ArchiveJobType.Repost && (
          <ul>
            <li>
              <strong>Reposting your job </strong> - new candidates will be
              added to the list of past candidates from your Archived job.
            </li>
            <li>
              {' '}
              To reuse your archived job details and see only new candidates
              click Copy job instead
            </li>
          </ul>
        )}

        <ModalFooter>
          {type === ArchiveJobType.Archive && (
            <>
              <Button onClick={handleCloseModal}>Cancel</Button>
              <Button
                type="primary"
                loading={isArchiveJobLoading}
                onClick={() => handleArchiveJob({ undo: false })}
              >
                Archive
              </Button>
            </>
          )}
          {type === ArchiveJobType.Repost && (
            <>
              <CloneJob
                title="Copy job"
                id={jobId.toString()}
                campaignType={Boolean(isJHCampaign)}
                buttonType="default"
              />

              <Button
                type="primary"
                loading={isUnArchiveJobLoading}
                onClick={() => handleUnArchiveJob()}
              >
                Repost job
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalItem>
    </>
  )
}

export default ArchiveJobModal
