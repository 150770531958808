import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import AddNoteModal from 'app/components/Modals/AddNoteModal/AddNoteModal'

import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { ActionsSideBarWrapper } from './styles'

import { JobStatus, RootState } from 'types'

const ActionsSideBar: FC = () => {
  const screens = useBreakpoint()

  const {
    id: candidateId,
    company: { id: companyId },
    advert: { id: advertId, status: jobStatus },
  } = useSelector(
    (state: RootState) => state.candidates.fetchCandidate.candidate!,
  )

  return (
    <ActionsSideBarWrapper>
      {screens.sm && (
        <>
          {jobStatus !== JobStatus.Archived && (
            <AddNoteModal
              buttonTitle="Add note"
              advertId={String(advertId)}
              companyId={companyId}
              candidateId={String(candidateId)}
            />
          )}
        </>
      )}
    </ActionsSideBarWrapper>
  )
}

export default ActionsSideBar
