import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { showDeleteJobModal, showExpireJobModal } from 'store/jobs'
import {
  getViewerSelector,
  isAdminSelector,
  isCompanyAdminSelector,
} from 'store/viewer'

import { checkHighRoleAccessibility } from 'helpers/jobHelpers'

import CloneJob from '../../CloneJob/CloneJob'
import JobAccessMultiposter from '../../JobAccessMultiposter/JobAccessMultiposter'
import KanbanIcon from '../assets/KanbanIcon'
import ArchiveJobModal from 'app/components/Modals/ArchiveJobModal/ArchiveJobModal'
import CvForwardSettings from 'app/components/Modals/CvForwardSettings/CvForwardSettings'
import ForwardIcon from 'assets/icons/Forward'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import {
  CloseOutlined,
  CopyOutlined,
  DatabaseOutlined,
  DeleteOutlined,
  EditOutlined,
  FieldTimeOutlined,
  SearchOutlined,
} from '@ant-design/icons'

import { MenuContainer, MenuItem } from './styles'

import { ArchiveJobType, IJob, JobStatus } from 'types'

interface JobSettingsMenuProps {
  job: IJob
  handleCloseDropDown: () => void
  handleCloseMobileMenu: () => void
  isCanInviteToJob: boolean
}

const JobSettingsMenu: FC<JobSettingsMenuProps> = ({
  job,
  handleCloseMobileMenu,
  handleCloseDropDown,
}) => {
  const {
    status,
    title,
    id,
    isExpired,
    campaignType,
    forwardTo,
    cvForwardingFrequency,
  } = job

  const screens = useBreakpoint()
  const dispatch = useDispatch()
  const isCompanyAdmin = useSelector(isCompanyAdminSelector)
  const isAdmin = useSelector(isAdminSelector)

  const viewer = useSelector(getViewerSelector)
  const isHighRole = checkHighRoleAccessibility(job.user.id, viewer)

  const isShowJobAccessMultiposterBtn = isAdmin && job.status === JobStatus.Live

  const isShowExpireJobBtn =
    isHighRole && !isExpired && job.status === JobStatus.Live

  const handleCloseMenu = () => {
    handleCloseDropDown()
    handleCloseMobileMenu()
  }

  const handleDeleteJob = () => {
    handleCloseMenu()
    dispatch(
      showDeleteJobModal({
        jobTitle: title,
        jobId: id.toString(),
        jobStatus: status,
      }),
    )
  }

  const handleExpireJob = () => {
    handleCloseMenu()
    dispatch(
      showExpireJobModal({
        jobTitle: title,
        jobId: id.toString(),
      }),
    )
  }

  const isShowEditButton =
    ((isAdmin && status === JobStatus.Live) ||
      (isHighRole && status !== JobStatus.Live)) &&
    status !== JobStatus.Archived

  const isShowArchiveButton = isHighRole && status !== JobStatus.Archived

  return (
    <MenuContainer>
      {isShowEditButton && (
        <MenuItem>
          <Link to={`${ROUTES.JOB}/${id}/edit`}>
            <Button icon={<EditOutlined />} type="text">
              Edit job
            </Button>
          </Link>
        </MenuItem>
      )}

      <MenuItem>
        <CloneJob
          title="Copy job"
          id={id.toString()}
          campaignType={campaignType}
          icon={<CopyOutlined />}
        />
      </MenuItem>

      {isCompanyAdmin && (
        <MenuItem>
          <Button icon={<KanbanIcon />} type="text">
            <Link to={`${ROUTES.SETTING_JOBS_STAGES_EDIT}`}>Edit stages</Link>
          </Button>
        </MenuItem>
      )}

      {isShowJobAccessMultiposterBtn && (
        <MenuItem>
          <JobAccessMultiposter
            title="Access Multiposter"
            type="text"
            jobId={job.id}
            icon={<SearchOutlined />}
          />
        </MenuItem>
      )}
      {isHighRole && (
        <>
          <Divider className="job-menu-divider" />
          <MenuItem onClick={() => handleCloseMenu()}>
            <CvForwardSettings
              jobId={id}
              forwardTo={forwardTo}
              cvForwardingFrequency={cvForwardingFrequency}
              buttonTitle="CV Forwarding"
              icon={<ForwardIcon />}
            />
          </MenuItem>
        </>
      )}

      {isShowExpireJobBtn && (
        <MenuItem>
          <Button
            type="text"
            onClick={handleExpireJob}
            icon={<FieldTimeOutlined />}
          >
            Expire job boards
          </Button>
        </MenuItem>
      )}
      {isShowArchiveButton && (
        <MenuItem onClick={handleCloseMenu}>
          <ArchiveJobModal
            type={ArchiveJobType.Archive}
            title={title}
            buttonTitle="Archive job"
            jobId={id}
            icon={<DatabaseOutlined />}
          />
        </MenuItem>
      )}

      {isHighRole && (
        <MenuItem>
          <Button
            onClick={handleDeleteJob}
            icon={<DeleteOutlined />}
            type="text"
            block
          >
            Delete job
          </Button>
        </MenuItem>
      )}

      {screens.xs && (
        <>
          <Divider className="job-menu-divider" />
          <MenuItem>
            <Button
              icon={<CloseOutlined />}
              type="text"
              block
              onClick={handleCloseMobileMenu}
            >
              Cancel
            </Button>
          </MenuItem>
        </>
      )}
    </MenuContainer>
  )
}

export default JobSettingsMenu
