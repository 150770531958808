import { DefaultTheme } from 'styled-components'

export interface IColors {
  white: '#ffffff'

  red4: '#FF7875'
  red5: '#FF4D4F'
  red6: '#F5222D'

  orange1: '#FFF7E6'
  orange2: '#FFE7BA'
  orange3: '#FFD591'
  orange4: '#FFC069'
  orange5: '#FFA940'
  orange6: '#FF773E'

  blue0: '#F8FDFF'
  blue1: '#E6F7FF'
  blue2: '#BAE7FF'
  blue3: '#91D5FF'
  blue5: '#40A9FF'
  blue6: '#2688FC'
  blue7: '#096DD9'

  gray0: '#F8F8F8'
  gray2: '#FAFAFA'
  gray3: '#F8F8F8'
  gray4: '#F0F0F0'
  gray5: '#D9D9D9'
  gray6: '#BFBFBF'
  gray7: '#8C8C8C'
  gray8: '#595959'
  gray9: '#262626'
  gray10: '#000000'
}

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: IColors
    fonts: {
      primary: 'Inter'
    }
    space: {
      medium: '24px'
    }
  }
}

const theme: DefaultTheme = {
  colors: {
    white: '#ffffff',

    red4: '#FF7875',
    red5: '#FF4D4F',
    red6: '#F5222D',

    orange1: '#FFF7E6',
    orange2: '#FFE7BA',
    orange3: '#FFD591',
    orange4: '#FFC069',
    orange5: '#FFA940',
    orange6: '#FF773E',

    blue0: '#F8FDFF',
    blue1: '#E6F7FF',
    blue2: '#BAE7FF',
    blue3: '#91D5FF',
    blue5: '#40A9FF',
    blue6: '#2688FC',
    blue7: '#096DD9',

    gray0: '#F8F8F8',
    gray2: '#FAFAFA',
    gray3: '#F8F8F8',
    gray4: '#F0F0F0',
    gray5: '#D9D9D9',
    gray6: '#BFBFBF',
    gray7: '#8C8C8C',
    gray8: '#595959',
    gray9: '#262626',
    gray10: '#000000',
  },
  fonts: {
    primary: 'Inter',
  },
  space: {
    medium: '24px',
  },
}

export default theme
