import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import CandidateStatusMenu from './CandidateStatusMenu'
import ChangeCandidateStatusModal from 'app/components/Modals/ChangeCandidateStatusModal/ChangeCandidateStatusModal'
import ForwardCVModal from 'app/components/Modals/ForwardCVModal/ForwardCVModal'
import UpdateCandidateStage from 'app/components/Modals/UpdateCandidateStage/UpdateCandidateStage'

import Button from 'antd/lib/button'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Space from 'antd/lib/space'

import {
  CandidateNumber,
  Container,
  Content,
  ForwardMenu,
  LeftContent,
  Wrapper,
} from './styles'

import { JobStatus, RootState } from 'types'

interface IUpdateCandidateStatus {
  candidatesId: string[]
  setRows: (selectedRows: []) => void
  candidatesIdWithCv: number[]
}

const UpdateCandidateStatus: FC<IUpdateCandidateStatus> = ({
  candidatesId,
  setRows,
  candidatesIdWithCv,
}) => {
  const screens = useBreakpoint()

  const { status: jobStatus, id } = useSelector(
    (state: RootState) => state.jobs.fetchJob.job!,
  )

  const isStatusVisible = jobStatus !== JobStatus.Archived

  const clearSelectionRows = () => setRows([])

  return (
    <Container>
      <Wrapper>
        <Content>
          {screens.lg && (
            <LeftContent>
              <CandidateNumber>
                {candidatesId.length}{' '}
                {candidatesId.length === 1 ? 'candidate' : 'candidates'}
              </CandidateNumber>
              selected
              <Button type="link" onClick={clearSelectionRows}>
                Clear selection
              </Button>
            </LeftContent>
          )}

          <Space>
            {isStatusVisible && (
              <CandidateStatusMenu
                isDefaultColored={true}
                clearSelectionRows={clearSelectionRows}
                candidatesId={candidatesId}
                jobId={String(id)}
              />
            )}

            <ForwardMenu>
              <ForwardCVModal
                clearSelectionRows={clearSelectionRows}
                candidateId={candidatesIdWithCv}
                buttonTitle="Forward CVs"
                icon={false}
                isHasCv={!!candidatesIdWithCv.length}
                isButton
              />
            </ForwardMenu>

            <ChangeCandidateStatusModal
              clearSelectionRows={clearSelectionRows}
              advert={{ id: String(id), status: jobStatus }}
              candidatesId={candidatesId}
              isButton
            />

            <UpdateCandidateStage
              clearSelectionRows={clearSelectionRows}
              isButton
              candidatesId={candidatesId}
              advertStatus={jobStatus}
            />
          </Space>
        </Content>
      </Wrapper>
    </Container>
  )
}

export default UpdateCandidateStatus
