import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  fetchCandidateCountByFiltersPromiseCreator,
  fetchCandidateCountByKeywordPromiseCreator,
  listCandidatesPromiseCreator,
  updateCandidatesStatusPromiseCreator,
} from 'store/candidates'
import { isExternalRecruiterSelector } from 'store/viewer'

import { getStoredCandidateFilters } from 'helpers/candidateHelpers'

import { candidateStatuses } from '../../Candidate/constanst'
import snackbar from 'app/components/Snackbar/Snackbar'
import useActionsRoutines from 'hooks/useActionsRoutines'
import { ROUTES } from 'routes'

import { RadioChangeEvent } from 'antd'
import Button from 'antd/lib/button'
import Menu from 'antd/lib/menu'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Space from 'antd/lib/space'

import { ExpandOutlined, InfoCircleOutlined } from '@ant-design/icons'

import { RadioList } from './styles'

import { CandidateStatus, JobStatus, RootState } from 'types'

interface IChangeCandidateStatusModal {
  candidatesId: string[]
  currentStatus?: CandidateStatus
  isButton?: boolean
  advert: { id: string; status: string }
  clearSelectionRows?: () => void
}

const ChangeCandidateStatusModal: FC<IChangeCandidateStatusModal> = ({
  candidatesId,
  currentStatus,
  isButton,
  advert,
  clearSelectionRows,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [status, setStatus] = useState(currentStatus)

  const listCandidatesAction = useActionsRoutines(listCandidatesPromiseCreator)
  const fetchCandidateCountByKeyword = useActionsRoutines(
    fetchCandidateCountByKeywordPromiseCreator,
  )
  const fetchCandidateCountByFilters = useActionsRoutines(
    fetchCandidateCountByFiltersPromiseCreator,
  )
  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)

  const { isLoading: isLoadingList } = useSelector(
    (state: RootState) => state.candidates.listCandidates,
  )

  const { isLoading: isUpdating } = useSelector(
    (state: RootState) => state.candidates.updateCandidatesStatus,
  )

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const handleChangeStatus = (e: RadioChangeEvent) => {
    setStatus(e.target.value)
  }

  const updateCandidatesStatus = useActionsRoutines(
    updateCandidatesStatusPromiseCreator,
  )

  const handleUpdateCandidatesStatus = () => {
    const jobId = advert.id
    updateCandidatesStatus({ candidatesId, status, jobId }).then(() => {
      fetchCandidateCountByKeyword(
        getStoredCandidateFilters({
          jobId,
          parseQs: true,
          isByKeywordOnly: true,
        }),
      )
      fetchCandidateCountByFilters(
        getStoredCandidateFilters({ jobId, parseQs: true }),
      )
      listCandidatesAction(
        getStoredCandidateFilters({ jobId, parseQs: true }),
      ).then(() => {
        clearSelectionRows && clearSelectionRows()
        if (!window.location.pathname.includes(ROUTES.CANDIDATE)) {
          snackbar({
            content: `Status changed to "${status}"  ${
              candidatesId.length > 1
                ? `for ${candidatesId.length} candidates`
                : ''
            }.`,
            showIcon: true,
            type: 'info',
            icon: InfoCircleOutlined,
            closeText: 'OK',
          })
        }
        handleCloseModal()
      })
    })
  }

  const isCanUpdateStatus =
    !isExternalRecruiter && advert.status !== JobStatus.Archived

  const isLoading = isLoadingList || isUpdating

  if (!isCanUpdateStatus) return null

  return (
    <div>
      {isButton ? (
        <Button onClick={handleOpenModal}>Change status</Button>
      ) : (
        <Menu.Item
          key="Change Status"
          icon={<ExpandOutlined />}
          onClick={handleOpenModal}
        >
          Change status
        </Menu.Item>
      )}
      <Modal
        width={550}
        footer={null}
        title="Change status"
        visible={isOpenModal}
        onCancel={handleCloseModal}
      >
        <Radio.Group value={status} onChange={handleChangeStatus}>
          <RadioList direction="vertical">
            {candidateStatuses.map(({ title, value }) => (
              <Radio key={value} value={value}>
                <p>{title}</p>
              </Radio>
            ))}
          </RadioList>
        </Radio.Group>

        <div>
          <Space>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button
              type="primary"
              loading={isLoading}
              onClick={handleUpdateCandidatesStatus}
            >
              Submit
            </Button>
          </Space>
        </div>
      </Modal>
    </div>
  )
}

export default ChangeCandidateStatusModal
