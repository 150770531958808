import { all, fork } from 'redux-saga/effects'

import * as W from './watchers'

export function* watchCandidatesActions() {
  yield all([
    fork(W.watchFetchCandidate),
    fork(W.watchListCandidates),
    fork(W.watchFetchCandidateCountByKeyword),
    fork(W.watchFetchCandidateCountByFilters),
    fork(W.watchAddCandidate),
    fork(W.watchAddCandidates),
    fork(W.watchFetchCandidateAssociatedMembers),
    fork(W.watchUpdateCandidatesStatus),
    fork(W.watchPostCandidateNote),
    fork(W.watchFetchCandidateTimeline),
    fork(W.watchFetchCandidateNotes),
    fork(W.watchDeleteCandidate),
    fork(W.watchParseCandidateByER),
    fork(W.watchUpdateCandidate),
    fork(W.watchDownloadCv),
    fork(W.watchForwardCandidates),
    fork(W.watchUpdateCandidatesStage),
  ])
}
