import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { isExternalRecruiterSelector } from 'store/viewer'

import CandidateMenu from '../CandidateMenu/CandidateMenu'
import UpdateCandidateStage from '../CandidateMenu/UpdateCandidateStage'
import CandidateStatusTag from '../CandidateStatusTag/CandidateStatusTag'
import CandidateStatusMenu from '../UpdateCandidateStatus/CandidateStatusMenu'
import { ROUTES } from 'routes'

import Button from 'antd/lib/button'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'

import {
  CandidateActionsWrapper,
  CandidateNumber,
  NavigationButtons,
} from './styles'

import { ICandidate, JobStatus } from 'types'

interface ICandidateActions {
  candidate: ICandidate
}

const CandidateActions: FC<ICandidateActions> = ({ candidate }) => {
  const history = useHistory()
  const {
    advert,
    status,
    id,
    previousCandidate,
    nextCandidate,
    sequencesNumber,
    total,
  } = candidate

  const isExternalRecruiter = useSelector(isExternalRecruiterSelector)
  const screens = useBreakpoint()
  const isStatusVisible =
    !isExternalRecruiter && advert.status !== JobStatus.Archived

  return (
    <CandidateActionsWrapper>
      <CandidateStatusTag status={status} />

      {isStatusVisible && (
        <>
          <CandidateStatusMenu
            status={status}
            candidatesId={[String(id)]}
            jobId={String(advert.id)}
            candidatePage
          />
          <UpdateCandidateStage />
        </>
      )}
      {screens.md && (
        <CandidateMenu
          candidate={candidate}
          isStatusVisible={isStatusVisible}
        />
      )}

      <NavigationButtons>
        {previousCandidate && (
          <Button
            icon={<CaretLeftOutlined />}
            onClick={() =>
              history.push(`${ROUTES.CANDIDATE}/${previousCandidate}`)
            }
          />
        )}

        <CandidateNumber>
          {sequencesNumber} of {total}
        </CandidateNumber>

        {nextCandidate && (
          <Button
            size="middle"
            onClick={() => history.push(`${ROUTES.CANDIDATE}/${nextCandidate}`)}
            icon={<CaretRightOutlined />}
          />
        )}
      </NavigationButtons>
    </CandidateActionsWrapper>
  )
}

export default CandidateActions
